import React from "react"
import { Helmet } from "react-helmet"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { PageProps } from "gatsby"

import { useAnalytics } from "../../hooks"

import {
  ButtonWithLoader,
  Button,
  InputWithFormik,
  InputSelect,
  TextArea,
} from "../../components"

import BreezeLogo from "../../images/breeze-logo-full.inline.svg"
import TickIcon from "../../images/icons/verified--green.inline.svg"

export default ({}: PageProps) => {
  const analytics = useAnalytics()

  const [error, setError] = React.useState("")
  const [completed, setCompleted] = React.useState(false)

  analytics.track(["User", "Page View"], { page: "/register/complete-signup/" })

  const handleSubmit = async (
    values: {
      email: string
      phone: string
      profession: string
      courseCompleted: string
      placeOfWork: string
    },
    {
      setSubmitting,
    }: {
      setSubmitting: (arg0: boolean) => void
    }
  ) => {
    try {
      const response: any = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/register/update-registrant`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...values,
          }),
        }
      )
      const data = await response.json()

      if (response.status === 200) {
        setCompleted(true)
      } else {
        throw data.message
      }
    } catch (error) {
      if (typeof error === "string") {
        setError(error)
      } else {
        setError("Could not complete profile set up")
      }
      console.log(error)
    } finally {
      setSubmitting(false)
    }
  }

  return completed ? (
    <div className="wrapperWidth checkoutSucceeded">
      <Helmet title="Profile Setup Finished | Breeze Academy" />
      <div className="checkoutSucceededIcon">
        <TickIcon />
      </div>
      <h1 className="checkoutHeading">Profile setup finished</h1>
      <p className="checkoutSucceededDesc">
        Welcome to the Breeze Academy register, you have joined like-minded
        professionals who wish to show the general public that they are serious
        about safe and effective client care.
      </p>
      <p className="checkoutSucceededDesc">
        Your membership is set up as an annual direct debit, which will come out
        on the same day each year (the date on which you first signed up to the
        register).
      </p>
      <p className="checkoutSucceededDesc">
        We will be in touch closer to the time of your renewal period, to ask
        that you complete the self-declaration.
      </p>
      <Button styles="buttonPrimary" to="/register/">
        View the register
      </Button>
    </div>
  ) : (
    <Formik
      initialValues={{
        email: "",
        phone: "",
        profession: "",
        courseCompleted: "",
        placeOfWork: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email address")
          .required("Please enter your email"),
        phone: Yup.string().required("Please enter your phone number"),
        profession: Yup.string().required("Please choose your profession"),
        courseCompleted: Yup.string().required(
          "Please choose the last course you completed with Breeze Academy"
        ),
        placeOfWork: Yup.string().required(
          "Please enter the address of your workplace"
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <div className="backgroundGreyLightSuper pt-8 pb-8">
            <section className="wrapperWidth checkoutWrapper checkoutBooking">
              <h1 className="checkoutHeading">
                Complete your register profile
              </h1>
              <span className="checkoutBookingAddress">
                This form uses your Thinkific account to set up a profile on the
                Breeze Academy register.
              </span>
            </section>
          </div>
          <Form className="wrapperWidth pt-8 pb-8 checkoutWrapper">
            <Helmet title="Complete Your Register Profile | Breeze Academy" />
            <div className="mb-4">
              <BreezeLogo />
            </div>
            <InputWithFormik
              name="email"
              label="Email"
              helpText="This should be the same email you use to sign into Thinkific"
            />
            <InputWithFormik
              name="phone"
              label="Phone"
              helpText="Only used for our records. Your phone number will not be shared on the register"
            />
            <InputSelect
              name="profession"
              label="Profession"
              options={[
                { label: "Please select...", value: "" },
                { label: "Acupuncturist", value: "Acupuncturist" },
                { label: "Chiropractor", value: "Chiropractor" },
                { label: "Dentist", value: "Dentist" },
                { label: "Doctor", value: "Doctor" },
                { label: "Massage Therapist", value: "Massage Therapist" },
                { label: "Midwife", value: "Midwife" },
                { label: "Nurse", value: "Nurse" },
                { label: "Osteopath", value: "Osteopath" },
                {
                  label: "Occupational Therapist",
                  value: "Occupational Therapist",
                },
                { label: "Physiotherapist", value: "Physiotherapist" },
                { label: "Podiatrist", value: "Podiatrist" },
                { label: "Sports Therapist", value: "Sports Therapist" },
                { label: "Other", value: "Other" },
              ]}
            />
            <InputSelect
              name="courseCompleted"
              label="Breeze Academy course completed"
              options={[
                { label: "Please select...", value: "" },
                {
                  label: "Acupuncture and Dry Needling Foundation Course",
                  value: "Acupuncture and Dry Needling Foundation Course",
                },
                {
                  label: "Level 3 Sports Massage Therapy Course",
                  value: "Level 3 Sports Massage Therapy Course",
                },
                {
                  label: "Level 4 Sports Massage Therapy Course",
                  value: "Level 4 Sports Massage Therapy Course",
                },
                {
                  label: "Accelerated Sports Massage Course (Level 3 and 4)",
                  value: "Accelerated Sports Massage Course (Level 3 and 4)",
                },
                {
                  label:
                    "Strength, Conditioning and Exercise Prescription, Fundamentals For Therapists",
                  value:
                    "Strength, Conditioning and Exercise Prescription, Fundamentals For Therapists",
                },
                {
                  label: "Clinical Yoga Teacher Training",
                  value: "Clinical Yoga Teacher Training",
                },
              ]}
            />
            <TextArea
              label="Place of work address"
              name="placeOfWork"
              helpText="This should be the address of a hospital or practice"
            />
            <ButtonWithLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Complete profile setup
            </ButtonWithLoader>
            {error && <div className="errorText">{error}</div>}
            <p className="smallText mt-4 mb-0">
              Once your profile setup is complete your name will be shown on
              breeze.academy/register. Share this link to improve your
              credibility within CPD.
            </p>
          </Form>
        </>
      )}
    </Formik>
  )
}
